import React from 'react';

function Hours() {
  return (
    <section id="hours" className="pt-36">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">◆ 稽古日</h2>
      <div className="p-4 bg-white shadow-md rounded-md">
        <table className="table-auto w-full text-center border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-gray-300"></th>
              <th className="bg-lime-700 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">月曜日</span>
                <span className="md:hidden">月</span>
              </th>
              <th className="bg-lime-700 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">火曜日</span>
                <span className="md:hidden">火</span>
              </th>
              <th className="bg-lime-700 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">水曜日</span>
                <span className="md:hidden">水</span>
              </th>
              <th className="bg-lime-700 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">木曜日</span>
                <span className="md:hidden">木</span>
              </th>
              <th className="bg-lime-700 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">金曜日</span>
                <span className="md:hidden">金</span>
              </th>
              <th className="bg-blue-700 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">土曜日</span>
                <span className="md:hidden">土</span>
              </th>
              <th className="bg-red-700 text-white px-2 md:px-4 py-2 border border-gray-300">
                <span className="hidden md:inline">日曜日／祝日</span>
                <span className="md:hidden">日祝</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-semibold px-2 md:px-4 py-2 border border-gray-300">昼の部<br/><span className="text-sm font-normal">13:00〜17:00</span></td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚫︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚫︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚫︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">△</td>
            </tr>
            <tr>
              <td className="font-semibold px-2 md:px-4 py-2 border border-gray-300">夜の部<br/><span className="text-sm font-normal">18:00〜21:00</span></td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">×</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚫︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">⚫︎</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">△</td>
              <td className="text-2xl px-2 md:px-4 py-2 border border-gray-300">△</td>
            </tr>
          </tbody>
        </table>
        <p className="text-sm mt-2">
          △ = 各種イベントなどによります<br />
          × = ご希望がある場合はご相談ください
        </p>
      </div>
    </section>
  );
}

export default Hours;
