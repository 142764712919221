import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-lime-700 text-white p-4 fixed w-full z-10 top-0">
      <div className="container mx-auto flex justify-between items-center max-w-screen-xl">
        <div>
          <div className="flex items-end">
            <Link to="/">
              <h1 className="text-4xl font-bold">静亮庵</h1>
            </Link>
            <span className="ml-6 text-lg align-baseline">（ 江戸千家渭白流 ）</span>
          </div>
          <p className="hidden md:block text-lg">
            Official Website of Edo Senke Seiryou School, Tea Ceremony Class Information
          </p>
          <p className="block md:hidden text-sm">
            Official Website of Edo Senke<br />Seiryou School, Tea Ceremony Class Information
          </p>
        </div>
        <button
          className="md:hidden text-4xl focus:outline-none"
          onClick={toggleMenu}
        >
          {isOpen ? '✕' : '☰'}
        </button>
        <nav className="hidden md:flex">
          <Link smooth to="/#curriculum" className="mr-4">料金</Link>
          <Link smooth to="/#events" className="mr-4">茶会</Link>
          <Link smooth to="/#about" className="mr-4">紹介</Link>
          <Link smooth to="/#hours" className="mr-4">稽古日</Link>
          <Link smooth to="/#belongings" className="mr-4">持ち物</Link>
          <Link smooth to="/#access" className="mr-4">アクセス</Link>
        </nav>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-lime-700 flex flex-col justify-center items-center text-4xl">
          <button
            className="absolute top-4 right-4 text-4xl focus:outline-none"
            onClick={toggleMenu}
          >
            ✕
          </button>
          <Link smooth to="/#curriculum" className="my-4 no-underline hover:underline" onClick={toggleMenu}>◆ 料金</Link>
          <Link smooth to="/#events" className="my-4 no-underline hover:underline" onClick={toggleMenu}>◆ 茶会</Link>
          <Link smooth to="/#about" className="my-4 no-underline hover:underline" onClick={toggleMenu}>◆ 紹介</Link>
          <Link smooth to="/#hours" className="my-4 no-underline hover:underline" onClick={toggleMenu}>◆ 稽古日</Link>
          <Link smooth to="/#belongings" className="my-4 no-underline hover:underline" onClick={toggleMenu}>◆ 持ち物</Link>
          <Link smooth to="/#access" className="my-4 no-underline hover:underline" onClick={toggleMenu}>◆ アクセス</Link>
        </div>
      )}
    </header>
  );
}

export default Header;
