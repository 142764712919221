import React from 'react';

// イベントデータ（画像URLを含む）
const events = [
  {
    title: "渭白会",
    location: "護国寺境内茶室（文京区）",
    participants: "全員",
    schedule: "毎年1回（不定期）",
    time: "8:00 〜 15:00",
    cost: "20,000円",
    notes: "※諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event1.JPG",
  },
  {
    title: "初釜",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年1月の第2日曜日",
    time: "10:00 〜 14:00",
    cost: "35,000円",
    notes: "※諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event2.jpeg",
  },
  {
    title: "総会",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年7月の第2 or 3日曜日",
    time: "10:00 〜 14:00",
    cost: "15,000円",
    notes: "※諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event3.jpeg",
  },
  {
    title: "納会",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年12月の第2 or 3日曜日",
    time: "10:00 〜 14:00",
    cost: "15,000円",
    notes: "※諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event4.jpeg",
  },
  {
    title: "研究会",
    location: "家元道場（稽古場）",
    participants: "師範以上",
    schedule: "毎年4回程度（不定期）",
    time: "10:00 〜 14:00",
    cost: "10,000円",
    notes: "※諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event5.jpeg",
  },
];

// イベントカードのレンダリング
const renderEvents = (events) => {
  return events.map((event, index) => {
    const isOdd = index % 2 === 0; // 偶数か奇数か判定

    return (
      <div
        key={index}
        className={`flex flex-col md:flex-row ${
          isOdd ? "md:flex-row-reverse" : ""
        } items-center bg-white shadow-md rounded-md p-4 mb-6`}
      >
        {/* 画像部分 */}
        <div className="w-full md:w-1/2">
          <img
            src={event.imageUrl}
            alt={event.title}
            className="w-full h-80 object-cover rounded-md shadow-sm"
          />
        </div>

        {/* 説明部分 */}
        <div className="w-full md:w-1/2 p-4">
          <h3 className="text-xl font-semibold bg-lime-700 text-white px-4 py-2 rounded mb-4">
            {event.title}
          </h3>
          <ul className="space-y-2">
            <li>
              <strong>場所：</strong> {event.location}
            </li>
            <li>
              <strong>参加者：</strong> {event.participants}
            </li>
            <li>
              <strong>日程：</strong> {event.schedule}
            </li>
            <li>
              <strong>時間：</strong> {event.time}
            </li>
            <li>
              <strong>費用：</strong> {event.cost}
            </li>
          </ul>
          <p className="text-sm mt-4">{event.notes}</p>
        </div>
      </div>
    );
  });
};

// メインのコンポーネント
function Events() {
  return (
    <section id="events" className="pt-36">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">◆ 茶会</h2>
      <div>{renderEvents(events)}</div>
    </section>
  );
}

export default Events;
