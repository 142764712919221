import React from 'react';

const curriculumItems = [
  {
    title: "授業料 (月謝制)",
    rows: [
      { label: "高校生以下", value: "10,000円" },
      { label: "一般部", value: "15,000円" },
      { label: "初伝以上", value: "18,000円" },
      { label: "奥伝以上", value: "20,000円" },
      { label: "皆伝以上", value: "12,000円" },
    ],
    notes: "※ 毎月、初回稽古日にお支払いください\n※ 稽古は週1回となります",
  },
  {
    title: "入会費",
    rows: [{ label: "一律", value: "20,000円" }],
    notes: "※ 入会時のみ、入会決定時にお支払いください",
  },
  {
    title: "年会費",
    rows: [
      { label: "高校生以下", value: "18,000円" },
      { label: "一般部", value: "24,000円" },
      { label: "初伝以上", value: "30,000円" },
    ],
    notes: "※ 入会初年度は不要です\n※ 毎年、前年の12月にお支払いください\n※ 諸経費（水屋費など）を含みます",
  },
  {
    title: "休会について",
    rows: [
      { label: "休会費", value: "授業料の2カ月分\n※休会申し出時のみ" },
      { label: "年会費", value: "10,000円/年\n※休会中でも、年会費は毎年お支払いください" },
      { label: "授業料", value: "4,000円/回" },
    ],
    notes: "※ 長期間欠席する場合のことです\n※ 諸経費（水屋費など）を含みます\n※ 休会中でも、上記授業料にてお稽古は可能です",
  },
];

const renderCurriculumItems = (items) => {
  return items.map((item, index) => (
    <div key={index} className="p-4 bg-white shadow-md rounded-md mb-4">
      <h3 className="bg-lime-700 text-white font-semibold px-4 py-2 mb-2 rounded">{item.title}</h3>
      <table className="min-w-full leading-normal border border-gray-300">
        <tbody>
          {item.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className="px-4 py-2 bg-gray-700 border border-white text-white">{row.label}</td>
              <td className="px-4 py-2 bg-white border border-gray-300">{row.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="text-sm mt-2">
        {item.notes.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  ));
};

function Curriculum() {
  return (
    <section id="curriculum" className="pt-36">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">◆ 料金</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderCurriculumItems(curriculumItems)}
      </div>
    </section>
  );
}

export default Curriculum;
