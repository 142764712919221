import React from 'react';

function AboutUs() {
  return (
    <section id="about" className="pt-36">
      <h2 className="font-semibold text-4xl mb-4 text-center md:text-left">◆ 紹介</h2>
      <div className="p-4 bg-white shadow-md rounded-md">
        <p className="mb-4 font-semibold text-lg tracking-widest leading-loose text-justify">
          本格的な茶室建築でありながら、アットホームな稽古場。今日あった出来事など、会話を楽しみながらの稽古風景。急がずに、それぞれのペースでゆっくりと学んでいく。気がつけば、会話がメインになっていることも、しばしば。「茶道」ってなんでしょう？私は「お茶でも飲みながら、みんなでその場を楽しむ作法」と考える。なんでもルール（作法）がある。そのルールをみんなで学ぶために時間であり、空間。ビシッと和服を着て完璧なお点前をする。みんながそうである必要はない。それぞれが今自分のできることを精一杯やって、その場の雰囲気を楽しくする。それが私の考える茶道。「伝統」なんて、現代人が作り出した観念にすぎない。
        </p>

        {/* 代表者情報表 */}
        <table className="font-semibold table-auto w-full text-left border border-gray-300 mb-4">
          <tbody>
            <tr>
              <th className="px-4 py-2 bg-gray-700 text-white border border-gray-300">代表者名</th>
              <td className="px-4 py-2 border border-gray-300">岸田俊太郎</td>
            </tr>
            <tr>
              <th className="px-4 py-2 bg-gray-700 text-white border border-gray-300">茶道名</th>
              <td className="px-4 py-2 border border-gray-300">静亮</td>
            </tr>
            <tr>
              <th className="px-4 py-2 bg-gray-700 text-white border border-gray-300">江戸千家渭白流</th>
              <td className="px-4 py-2 border border-gray-300">若宗匠、時期家元</td>
            </tr>
          </tbody>
        </table>

        {/* 画像ギャラリー */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <img
            src="/images/seiryo.JPG"
            alt="Tea Room"
            className="w-full h-72 object-cover rounded-md"
          />
          <img
            src="/images/me.jpeg"
            alt="Tea Ceremony"
            className="w-full h-72 object-cover rounded-md"
          />
          <img
            src="/images/inside.jpeg"
            alt="Tea Tools"
            className="w-full h-72 object-cover rounded-md"
          />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
